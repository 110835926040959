import React from "react";
import cx from "classnames";

import { StarBoxIcon } from "./StarBoxIcon";

import s from "./Stars.module.scss";

export type TProps = {
	className?: string;
	numOpaque?: number;
	numTransparent?: number;
	reverse?: boolean;
};

export const Stars = ({ numOpaque, numTransparent, className, reverse }: TProps): JSX.Element => {
	const opaque = numOpaque ?? 0;
	const transparent = numTransparent ?? 0;
	return (
		<div className={cx(s.main, className)}>
			{new Array(opaque + transparent).fill(0).map((v, i) => {
				const isTransparent = reverse ? i < transparent : i >= opaque;
				const classes = cx(s.starBox, isTransparent ? s.transparent : undefined);
				return (
					<div key={`star-${i}`} className={classes}>
						<StarBoxIcon className={s.starBoxIcon} />
					</div>
				);
			})}
		</div>
	);
};
