import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Dialog } from "./components/Dialog";
import { Field } from "./components/Field";
import { Header } from "./components/Header";
import { Key } from "./components/Key";
import { Keyboard } from "./components/Keyboard";
import { MenuBar } from "./components/MenuBar";
import { SideMenu } from "./components/SideMenu";
import { SlidingMessage } from "./components/SlidingMessage";
import { Stars } from "./components/Stars";
import { TAddToastMessageFunc, Toasts } from "./components/Toasts";
import { TextInstructions } from "./components/TextInstructions";
import { TextArchive } from "./components/TextArchive";
import { TextSettings } from "./components/TextSettings";
import useDate from "./hooks/useDate";
import useSolution from "./hooks/useSolution";
import useInputMapping from "./hooks/useInputMapping";
import useSymbols from "./hooks/useSymbols";
import usePosition from "./hooks/usePosition";
import useGuesses from "./hooks/useGuesses";
import useScore from "./hooks/useScore";
import useSettings, { LightMode } from "./hooks/useSettings";
import useKeyboardLayout from "./hooks/useKeyboardLayout";
import usePersistentState, { cleanupPersistentData } from "./hooks/usePersistentState";
import { createShareText } from "./utils/ShareUtils";
import { areLettersCompatible, uniqueLetters } from "./utils/AlphabetUtils";
import { KeyboardKey, getKeyboardLayoutFromId, keyCodeToKeyTry, keyToLetterTry } from "./data/KeyboardLayout";
import { DATA_VERSION } from "./data/Constants";
import { setPageClassName } from "./utils/StyleUtils";

import s from "./App.module.scss";

// TODO: remove later
cleanupPersistentData("game-seen-instructions-v", DATA_VERSION - 1);

const App = (): JSX.Element => {
	const [hasSeenInstructions, setHasSeenInstructions] = usePersistentState<boolean>(
		`game-seen-instructions-v${DATA_VERSION}`,
		false,
	);
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const [isDialogInstructionsVisible, setIsDialogInstructionsVisible] = useState(!hasSeenInstructions);
	const [isDialogArchiveVisible, setIsDialogArchiveVisible] = useState(false);
	const [isDialogSettingsVisible, setIsDialogSettingsVisible] = useState(false);
	const [addToastMessage, setAddToastMessage] = useState<TAddToastMessageFunc | null>(null);
	const [isAnimatingHint, setIsAnimatingHint] = useState(false);

	// Find the unique day
	const date = useDate(0);

	// Pick words for that day
	const solution = useSolution(date.index, date.id);

	// Find the symbols for each word
	const symbols = useSymbols(date.index, solution.words);

	// Creates a map of symbol:letter combinations entered so far
	const inputMapping = useInputMapping(date.index);

	// Keeps track of guesses (symbol:letter pairs "checked" so far)
	const guesses = useGuesses(date.index);

	// Controls the position on the field
	const position = usePosition(0, 0);

	// Read and apply settings
	const settings = useSettings();
	useEffect(() => {
		setPageClassName("lightMode-auto", settings.lightMode === LightMode.Auto);
		setPageClassName("lightMode-always-dark", settings.lightMode === LightMode.AlwaysDark);
		setPageClassName("lightMode-always-light", settings.lightMode === LightMode.AlwaysLight);
		setPageClassName("highContrast-enabled", settings.highContrast);
		setPageClassName("highContrast-disabled", !settings.highContrast);
	}, [settings.lightMode, settings.highContrast]);

	// Keep track of the score
	const {
		day: dayScore,
		general: generalScore,
		archive: archiveScore,
	} = useScore(date.index, date.realIndex, date.isGameDay);
	const shareText = createShareText(date.index, date.isGameDay, solution, dayScore, generalScore, symbols);

	const isPlaying = !dayScore.hasLost && !dayScore.hasWon;
	const isCheckable = useMemo(() => {
		// If nothing entered, or not playing, cannot check
		const hasMatchesToCheck = inputMapping.size > 0 && isPlaying;
		if (!hasMatchesToCheck) {
			return false;
		}

		// Check if any *new* input mappings exist
		for (const [symbol, letter] of Object.entries(inputMapping.map)) {
			const alreadyCheckedRight = guesses.right.includes(symbol);
			const alreadyCheckedWrong = guesses.wrong.some(([s, l]) => s === symbol && l === letter);
			if (!alreadyCheckedRight && !alreadyCheckedWrong) {
				// New value for this pair
				return true;
			}
		}

		// Nothing new
		return false;
	}, [inputMapping.map, inputMapping.size, isPlaying, guesses.right, guesses.wrong]);

	const currentSymbol = useMemo((): string => {
		return symbols.field[position.row][position.col];
	}, [position.row, position.col, symbols.field]);

	const previousSymbol = useMemo((): string => {
		return symbols.field[position.prevRow][position.prevCol];
	}, [position.prevRow, position.prevCol, symbols.field]);

	const isOnRightLetter = useMemo((): boolean => {
		return guesses.right.includes(currentSymbol);
	}, [guesses.wrong, currentSymbol]);

	const isAfterRightLetter = useMemo((): boolean => {
		return guesses.right.includes(previousSymbol);
	}, [guesses.wrong, previousSymbol]);

	const currentSymbolHasLetter = useMemo((): boolean => {
		return inputMapping.has(currentSymbol);
	}, [inputMapping, currentSymbol]);

	const handlePressKey = useCallback(
		(key: KeyboardKey) => {
			if (key === KeyboardKey.Backspace) {
				// Backspace
				if (currentSymbolHasLetter) {
					// Attempt to remove current position
					if (!isOnRightLetter) {
						inputMapping.unset(currentSymbol);
					}
				} else {
					// Attempt to remove previous position
					if (!isAfterRightLetter) {
						const newPosition = position.goToPrev();
						const symbol = symbols.field[newPosition.row][newPosition.col];
						inputMapping.unset(symbol);
					}
				}
			} else if (key === KeyboardKey.ArrowUp) {
				position.goUp();
			} else if (key === KeyboardKey.ArrowDown) {
				position.goDown();
			} else if (key === KeyboardKey.ArrowLeft) {
				position.goLeft();
			} else if (key === KeyboardKey.ArrowRight) {
				position.goRight();
			} else {
				// Letter or something else
				const letter = keyToLetterTry(key);
				if (letter) {
					const cellHasLetter = inputMapping.has(currentSymbol);
					if (inputMapping.get(currentSymbol) !== letter) {
						inputMapping.set(currentSymbol, letter);
						if (cellHasLetter) {
							// If typed on a cell that already had a letter,
							// just go to the next cell
							position.goToNext();
						} else {
							// If typing on a cell that had no letter,
							// skip to the next one without a letter
							position.goToNextWithoutMapping(symbols.field, inputMapping.has, [currentSymbol]);
						}
					}
				}
			}
		},
		[
			isOnRightLetter,
			isAfterRightLetter,
			currentSymbol,
			currentSymbolHasLetter,
			position.goToPrev,
			position.goToNext,
			position.goToNextWithoutMapping,
			position.goUp,
			position.goDown,
			position.goLeft,
			position.goRight,
			symbols.field,
			inputMapping.has,
			inputMapping.get,
			inputMapping.set,
			inputMapping.unset,
		],
	);

	const handlePressCell = useCallback(
		(row: number, col: number) => {
			position.set(row, col);
		},
		[position.set],
	);

	const handleRequestHint = useCallback(() => {
		// Will reveal one of the unrevealed words, defaulting to the one that has the biggest amount of unique, unrevealed letters

		const allLetters = solution.words.join("");
		const letterUsedTimes = Object.fromEntries(
			new Map(
				uniqueLetters(solution.words).map((letter) => [
					letter,
					(allLetters.match(new RegExp(letter, "g")) ?? []).length,
				]),
			),
		);

		// Create list of words
		const words = solution.words.map((word, i) => {
			const wordLetters = word.split("");
			const wordSymbols = symbols.field[i];
			const missingLetters = wordSymbols
				.filter((symbol) => !guesses.right.includes(symbol))
				.map((symbol) => wordLetters[wordSymbols.indexOf(symbol)]);
			const missingUniqueLetters = missingLetters.filter((letter) => letterUsedTimes[letter] === 1);
			return {
				letters: wordLetters,
				symbols: wordSymbols,
				missingLetters,
				missingUniqueLetters,
			};
		});

		// Pick a word that has the biggest number of missing unique letters, and then biggest number of missing letters
		const wordToReveal = words.sort((a, b) => {
			if (a.missingUniqueLetters.length > b.missingUniqueLetters.length) {
				return -1;
			} else if (a.missingUniqueLetters.length < b.missingUniqueLetters.length) {
				return 1;
			} else {
				return b.missingLetters.length - a.missingLetters.length;
			}
		})[0];

		// Finally, reveals the word
		const mappingsToReveal = wordToReveal.symbols
			.map((symbol, i) => ({ symbol, letter: wordToReveal.letters[i] }))
			.filter(({ symbol }) => !guesses.right.includes(symbol));

		let time = 0;
		dayScore.useHint();
		setIsAnimatingHint(true);
		for (const mapping of mappingsToReveal) {
			setTimeout(() => {
				inputMapping.unsetLetter(mapping.letter);
				inputMapping.set(mapping.symbol, mapping.letter);
				guesses.markRight(mapping.symbol);
			}, time);
			time += 500;
		}

		setTimeout(() => {
			setIsAnimatingHint(false);
		}, time);
	}, [dayScore.useHint, solution.words, symbols.field, guesses.right, inputMapping.set, inputMapping.unsetLetter]);

	const handleRequestCheck = useCallback(() => {
		const wrongSymbols: string[] = [];
		const blankSymbols: string[] = [];
		for (const entry of Object.entries(symbols.mapping)) {
			const [symbol, letter] = entry;
			if (inputMapping.has(symbol)) {
				const letterEntered = inputMapping.get(symbol);
				if (letterEntered === letter) {
					guesses.markRight(symbol);
				} else if (letterEntered) {
					wrongSymbols.push(symbol);
					guesses.markWrong(symbol, letterEntered);
				}
			} else {
				blankSymbols.push(symbol);
			}
		}
		dayScore.takeChance(wrongSymbols, blankSymbols);
	}, [
		inputMapping.has,
		inputMapping.get,
		solution,
		symbols.mapping,
		guesses.markRight,
		guesses.markWrong,
		dayScore.takeChance,
		date.offset,
	]);

	const expectedKeyboardLetter = useMemo((): string => {
		return solution.words[position.row].substring(position.col, position.col + 1);
	}, [position, solution]);

	const markKeyboardWrong = useMemo((): string[] => {
		return guesses.wrong.filter(([symbol]) => symbol === currentSymbol).map(([, letter]) => letter);
	}, [guesses.wrong, currentSymbol]);

	const isKeyboardDisabled = isMenuVisible || !isPlaying || isAnimatingHint;
	const isKeyboardLettersDisabled = isKeyboardDisabled || isOnRightLetter;
	const isKeyboardBackspaceDisabled = isKeyboardLettersDisabled || (!currentSymbolHasLetter && isAfterRightLetter);
	const keyboardLayout = useKeyboardLayout(
		isKeyboardDisabled,
		isKeyboardLettersDisabled,
		isKeyboardBackspaceDisabled,
		inputMapping.usedLetters,
		getKeyboardLayoutFromId(settings.keyboardLayout),
	);

	const handleAfterShare = useCallback(
		(usedClipboard: boolean) => {
			if (usedClipboard) {
				addToastMessage?.("The results were copied to the clipboard");
			} else {
				addToastMessage?.("The results were shared to the system");
			}
		},
		[addToastMessage],
	);

	const handleKeyUp = useCallback(
		(e: KeyboardEvent) => {
			if (!isKeyboardDisabled) {
				const key = keyCodeToKeyTry(e.code);
				if (key && !keyboardLayout.keys[key].isDisabled) {
					const letter = keyToLetterTry(key);
					const isSpecial = !letter;
					const isCompatibleLetter = Boolean(letter && areLettersCompatible(expectedKeyboardLetter, letter));
					if (isSpecial || isCompatibleLetter) {
						handlePressKey(key);
					}
				}
			}
		},
		[isKeyboardDisabled, handlePressKey, keyboardLayout.keys, expectedKeyboardLetter],
	);

	const handleClickMenuIcon = useCallback(() => {
		setIsMenuVisible(true);
	}, []);

	const handleCloseMenu = useCallback(() => {
		setIsMenuVisible(false);
	}, []);

	const handleCloseDialogInstructions = useCallback(() => {
		setIsDialogInstructionsVisible(false);
		setHasSeenInstructions(true);
	}, [setHasSeenInstructions]);

	const handleClickMenuInstructions = useCallback(() => {
		setIsDialogInstructionsVisible(true);
		setIsMenuVisible(false);
	}, []);

	const handleCloseDialogArchive = useCallback(() => {
		setIsDialogArchiveVisible(false);
	}, []);

	const handleClickMenuArchive = useCallback(() => {
		setIsDialogArchiveVisible(true);
		setIsMenuVisible(false);
	}, []);

	const handleClickArchiveDate = useCallback(
		(dayoffset: number) => {
			date.goToOffset(dayoffset);
			setIsDialogArchiveVisible(false);
		},
		[date.goToOffset],
	);

	const handleCloseDialogSettings = useCallback(() => {
		setIsDialogSettingsVisible(false);
	}, []);

	const handleClickMenuSettings = useCallback(() => {
		setIsDialogSettingsVisible(true);
		setIsMenuVisible(false);
	}, []);

	const canUseHint = dayScore.hintsLeft > 0 && dayScore.chancesLeft > 1;

	useEffect(() => {
		document.addEventListener("keyup", handleKeyUp);
		return () => {
			document.removeEventListener("keyup", handleKeyUp);
		};
	}, [handleKeyUp]);

	useEffect(() => {
		return () => {
			console.info("\n\n\nUnmounting; ignore console messages above!!!\n\n\n\n");
		};
	}, []);

	return (
		<div className={s.main}>
			<div className={s.game}>
				<MenuBar onClickMenuIcon={handleClickMenuIcon} />
				<SideMenu
					visible={isMenuVisible}
					generalScore={generalScore}
					onClose={handleCloseMenu}
					onClickInstructions={handleClickMenuInstructions}
					onClickArchive={handleClickMenuArchive}
					onClickSettings={handleClickMenuSettings}
				/>
				<Dialog title={"Instructions"} visible={isDialogInstructionsVisible} onClose={handleCloseDialogInstructions}>
					<TextInstructions onPressButton={handleCloseDialogInstructions} symbolStyles={symbols.styles} />
				</Dialog>
				<Dialog title={"Puzzle Archive"} visible={isDialogArchiveVisible} onClose={handleCloseDialogArchive}>
					<TextArchive generalScore={generalScore} archiveScore={archiveScore} onClickDate={handleClickArchiveDate} />
				</Dialog>
				<Dialog title={"Settings"} visible={isDialogSettingsVisible} onClose={handleCloseDialogSettings}>
					<TextSettings />
				</Dialog>
				<Header index={date.index} title={solution.name} />
				<Field
					disabled={!isPlaying}
					getLetterForSymbol={inputMapping.get}
					symbols={symbols.field}
					symbolStyles={symbols.styles}
					words={solution.words}
					reveal={!isPlaying}
					guesses={guesses}
					currentPosition={isPlaying ? position : undefined}
					currentSymbol={isPlaying ? currentSymbol : undefined}
					onPressCell={handlePressCell}
				/>
				<div className={s.chances}>
					<Stars
						className={s.chancesStars}
						numOpaque={dayScore.chancesLeft}
						numTransparent={dayScore.maxChances - dayScore.chancesLeft}
					/>
					<Key label={"HINT"} onPress={handleRequestHint} disabled={!canUseHint} isVeryLarge={true} />
					<Key label={"CHECK"} onPress={handleRequestCheck} disabled={!isCheckable} isVeryLarge={true} />
					<SlidingMessage
						message={"Congrats!"}
						isVisible={dayScore.hasWon}
						shareText={shareText}
						onAfterShare={handleAfterShare}
						showStars={true}
						starsMax={dayScore.maxChances}
						starsLeft={dayScore.chancesLeft}
					/>
					<SlidingMessage
						message={"Maybe next time."}
						isVisible={dayScore.hasLost}
						shareText={shareText}
						onAfterShare={handleAfterShare}
					/>
				</div>
				<Keyboard
					onPressKey={handlePressKey}
					markWrong={markKeyboardWrong}
					expectedLetter={expectedKeyboardLetter}
					layout={keyboardLayout}
				/>
				<Toasts onSetAddToastMessage={setAddToastMessage} />
			</div>
		</div>
	);
};

export default App;
